<template>
  <div
    id="switch-chain-metamask"
    v-show="provider !== null && account !== undefined && chainId !== '0x609e'"
  >
    <img
      alt="No MetaMask logo"
      class="logo"
      src="../assets/img/switch_chain.png"
    />
    <div class="text">
      <h2>Switch to MintMe Blockchain</h2>
      <p>
        Almost done, to swap tokens you have to connect MetaMask with this
        website, to do that click the button below.
      </p>
      <Button
        @btn-click="switchChain()"
        text="Switch to MintMe Blockchain"
        fx="expanded"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "SwitchChainMetaMask",
  components: {
    Button,
  },
  computed: {
    provider: {
      get() {
        return this.$store.state.provider;
      },
      set(value) {
        this.$store.state.provider = value;
      },
    },
    chainId: {
      get() {
        return this.$store.state.chainId;
      },
      set(value) {
        this.$store.state.chainId = value;
      },
    },
    account: {
      get() {
        return this.$store.state.account;
      },
      set(value) {
        this.$store.state.account = value;
      },
    },
  },
  mounted: async function () {
    if (this.isConnected) {
      this.getWeb3();
    }
  },
  methods: {
    async switchChain() {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x609E",
              chainName: "MintMe",
              nativeCurrency: {
                name: "mintme",
                symbol: "MINTME",
                decimals: 18,
              },
              rpcUrls: [
                "https://node.1000x.ch",
                "https://node1.mintme.com",
                "https://node2.mintme.com",
              ],
              blockExplorerUrls: ["https://www.mintme.com/explorer"],
            },
          ],
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#switch-chain-metamask {
  padding: 30px;
  border: 2px solid #333;
  background: #111;
  margin: 40px 0;

  .logo {
    margin-top: 30px;
    max-width: 160px;
  }

  .text {
    width: calc(100% - 220px);
    padding: 0 30px;
    float: right;
  }
}
</style>
