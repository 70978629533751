<template>
  <Swap
    :initFromAddress="$route.params.inputCurrency"
    :initToAddress="$route.params.outputCurrency"
  ></Swap>
</template>

<script>
import Swap from "@/views/Swap.vue";

export default {
  name: "SwapSelect",
  data() {
    return {};
  },
  components: {
    Swap,
  },
};
</script>

<style lang="scss" scoped></style>
