<template>
  <div class="tokens">
    <h1>Tokens</h1>
    <div>
      <h2>Token List</h2>
      <p>{{ info }}</p>
      <ul>
        <li :key="token.id" v-for="token in tokens">
          {{ token.name }} <a @click.prevent="setEditMode(token)">[Edit]</a>
          <a @click.prevent="setDelMode(token)">[Delete]</a>
        </li>
      </ul>
      <Button @btn-click="setNewMode" text="New Token" fx="expanded" />
    </div>
    <div v-show="mode == 'new'">
      <h2>Add new Token</h2>
      <form>
        <label for="name">Name:</label>
        <input type="text" name="name" v-model="name" />
        <label for="symbol">Symbol:</label>
        <input type="text" name="symbol" v-model="symbol" />
        <label for="type">Standard:</label>
        <input type="text" name="standard" v-model="standard" />
        <label for="address">Address:</label>
        <input type="text" name="address" v-model="address" />
        <label for="decimals">Decimals:</label>
        <input type="number" name="decimals" v-model="decimals" />
        <label for="image">Image:</label>
        <input type="text" name="image" v-model="image" />
        <label for="hidden">Hidden:</label>
        <input
          type="checkbox"
          name="hidden"
          v-model="hidden"
          v-bind:true-value="1"
          v-bind:false-value="0"
        />
        <input
          type="submit"
          name="edit"
          value="Create"
          @click.prevent="postToken"
        />
      </form>
    </div>
    <div v-show="mode == 'edit'">
      <h2>Edit Token</h2>
      <form>
        <label for="id">ID:</label>
        <input type="text" name="id" v-model="id" disabled />
        <label for="blockchainId">Blockchain ID:</label>
        <input
          type="text"
          name="blockchainId"
          v-model="blockchainId"
          disabled
        />
        <label for="name">Name:</label>
        <input type="text" name="name" v-model="name" />
        <label for="symbol">Symbol:</label>
        <input type="text" name="symbol" v-model="symbol" />
        <label for="type">Standard:</label>
        <input type="text" name="standard" v-model="standard" />
        <label for="address">Address:</label>
        <input type="text" name="address" v-model="address" />
        <label for="decimals">Decimals:</label>
        <input type="number" name="decimals" v-model="decimals" />
        <label for="image">Image:</label>
        <input type="text" name="image" v-model="image" />
        <label for="hidden">Hidden:</label>
        <input
          type="checkbox"
          name="hidden"
          v-model="hidden"
          v-bind:true-value="1"
          v-bind:false-value="0"
        />
        <input
          type="submit"
          name="edit"
          value="Edit"
          @click.prevent="putToken"
        />
      </form>
    </div>
    <div v-show="mode == 'del'">
      <h2>Delete Token</h2>
      <form>
        <label for="id">ID:</label>
        <input type="text" name="id" v-model="id" disabled />
        <label for="blockchainId">Blockchain ID:</label>
        <input
          type="text"
          name="blockchainId"
          v-model="blockchainId"
          disabled
        />
        <label for="name">Name:</label>
        <input type="text" name="name" v-model="name" disabled />
        <label for="address">Address:</label>
        <input type="text" name="address" v-model="address" disabled />
        <input
          type="submit"
          name="edit"
          value="Delete"
          @click.prevent="delToken"
        />
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Button from "@/components/Button.vue";
import { options } from "@/settings/options.js";

export default {
  name: "Tokens",
  data() {
    return {
      info: "",
      mode: "",

      id: 0,
      name: "",
      symbol: "",
      standard: "",
      address: "",
      decimals: 0,
      image: "",
      hidden: false,
      blockchainId: options.blockchainID,
    };
  },
  computed: {
    jwt: {
      get() {
        return this.$store.state.jwt;
      },
      set(value) {
        this.$store.state.jwt = value;
      },
    },
    tokens: {
      get() {
        return this.$store.state.tokens;
      },
      set(value) {
        this.$store.state.tokens = value;
      },
    },
  },
  methods: {
    setMode(mode) {
      this.mode = mode;
    },
    clearToken() {
      this.id = 0;
      this.name = "";
      this.symbol = "";
      this.standard = "ERC20";
      this.address = "";
      this.decimals = 12;
      this.image = "https://www.1000x.ch/token_icons/";
      this.hidden = false;
      this.blockchainId = options.blockchainID;
    },
    setEditMode(token) {
      this.id = token.id;
      this.name = token.name;
      this.symbol = token.symbol;
      this.standard = token.standard;
      this.address = token.address;
      this.decimals = token.decimals;
      this.image = token.image;
      this.hidden = Number(token.hidden);
      this.blockchainId = token.blockchainId;
      this.setMode("edit");
    },
    setDelMode(token) {
      this.id = token.id;
      this.name = token.name;
      this.symbol = token.symbol;
      this.standard = token.standard;
      this.address = token.address;
      this.decimals = token.decimals;
      this.image = token.image;
      this.hidden = Boolean(token.hidden);
      this.blockchainId = token.blockchainId;
      this.setMode("del");
    },
    setNewMode() {
      this.clearToken();
      this.setMode("new");
    },
    async putToken() {
      const headers = {
        Authorization: "Bearer " + this.jwt,
      };

      const token = {
        name: this.name,
        symbol: this.symbol,
        standard: this.standard,
        address: this.address,
        decimals: Number(this.decimals),
        image: this.image,
        hidden: Boolean(this.hidden),
        blockchainId: Number(this.blockchainId),
      };

      try {
        const res = await axios.put(
          options.apiURL + "/token/" + this.id,
          token,
          {
            headers,
          }
        );

        if (200 == res.status) {
          const newToken = res.data;
          const index = this.tokens.findIndex(
            (token) => token.id == newToken.id
          );

          this.tokens[index] = newToken;
          this.tokens = [...this.tokens];
          this.clearToken();
          this.setMode("");
          this.info = "Token updated.";
        } else {
          console.log(res);
          this.info = "Something went wrong.";
        }
      } catch (e) {
        console.log(e.message);
        this.info = e.message;
      }
    },
    async postToken() {
      const headers = {
        Authorization: "Bearer " + this.jwt,
      };

      const token = {
        name: this.name,
        symbol: this.symbol,
        standard: this.standard,
        address: this.address,
        decimals: Number(this.decimals),
        image: this.image,
        hidden: Boolean(this.hidden),
        blockchainId: Number(this.blockchainId),
      };

      try {
        const res = await axios.post(options.apiURL + "/token/", token, {
          headers,
        });

        if (201 == res.status) {
          const tempToken = res.data;

          const res2 = await axios.post(
            options.apiURL +
              "/swap/" +
              options.swapID +
              "/token/" +
              tempToken.id,
            token,
            {
              headers,
            }
          );

          if (201 == res2.status) {
            const newToken = res2.data;
            this.tokens.push(newToken);
            this.tokens = [...this.tokens];
            this.clearToken();
            this.setMode("");
            this.info = "New Token added.";
          }
        } else {
          console.log(res);
          this.info = "Something went wrong.";
        }
      } catch (e) {
        console.log(e.message);
        this.info = e.message;
      }
    },
    async delToken() {
      const headers = {
        Authorization: "Bearer " + this.jwt,
      };

      try {
        const res = await axios.delete(options.apiURL + "/token/" + this.id, {
          headers,
        });

        if (204 == res.status) {
          this.tokens = this.tokens.filter((token) => token.id != this.id);
          this.clearToken();
          this.setMode("");
          this.info = "Token removed.";
        } else {
          console.log(res);
          this.info = "Something went wrong.";
        }
      } catch (e) {
        console.log(e.message);
        this.info = e.message;
      }
    },
  },
  mounted: async function () {
    if (!this.jwt) this.$router.push("login");
  },
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
.tokens {
  padding: 0 30px 30px 30px;
}

a:hover {
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  height: 150px;
  overflow-y: scroll;
  background-color: #111;

  li {
    padding: 0 30px;

    &:nth-child(odd) {
      background-color: #333;
    }
  }
}

label {
  display: block;
  width: calc(100% - 30px);
  min-height: 20px;
  padding: 7px 15px;

  font-size: 25px;
}

input {
  display: block;
  width: calc(100% - 30px);
  min-height: 20px;
  border: none;
  padding: 7px 15px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-family: inherit;
  font-size: 20px;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  -ms-appearance: checkbox !important;
  -o-appearance: checkbox !important;
  appearance: checkbox !important;
  width: 30px;
  height: 30px;
}

input[type="submit"] {
  border: none;
  padding: 7px 15px;
  color: #fff;
  background-color: #8a8cff;
  width: 100%;

  &:hover {
    opacity: 0.7;
    background-color: #4f52ff;
    color: #1e1d1c;
    cursor: pointer;
  }

  &:disabled {
    background-color: #8a8cff;
    color: #fff;
    opacity: 0.5;
    &:hover {
      background-color: #8a8cff;
      color: #fff;
      opacity: 0.5;
    }
  }
}
</style>
