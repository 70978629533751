<template>
  <div class="add-liquidity-controls">
    <button class="max" @click="setToMax">max.</button>
    <input class="token" v-model="valueToken" type="number" step="any" />
    <select v-model="tokenSelect" :disabled="disabled">
      <option
        :key="token.address"
        v-for="token in tokens"
        :value="token.address"
        v-show="token.address != baseTokenAddress"
      >
        {{ token.name }}
      </option>
    </select>
    <input
      class="basetoken"
      v-model="valueBaseToken"
      type="number"
      step="any"
    />
    <Button
      @btn-click="addLiquidity"
      text="add Liquidity"
      fx="expanded"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { options } from "@/settings/options.js";
import Button from "@/components/Button.vue";
import * as Math from "@/utils/Math.js";

const ThousandXSWAPABI = require("@/abi/ThousandXSWAP.json");
const IERC20ABI = require("@/abi/IERC20.json");

export default {
  name: "AddLiquidityControls",
  components: {
    Button,
  },
  props: {
    disabled: Boolean,
  },
  methods: {
    async setToMax() {
      const max = await this.token.methods.balanceOf(this.account).call();
      const token = this.tokens.find(
        (token) => token.address == this.tokenSelect
      );
      this.valueToken = Math.toDecimal(max, token.decimals);
    },
    async addLiquidity() {
      const token = this.tokens.find(
        (token) => token.address == this.tokenSelect
      );

      const tokenAmount = Math.toInt(this.valueToken, token.decimals);
      const baseAmount = Math.toInt(this.valueBaseToken, 12);

      try {
        const tokenAllowance = await this.token.methods
          .allowance(this.account, options.thousandXSWAPAddress)
          .call();

        if (0 > Math.compare(tokenAllowance, tokenAmount)) {
          this.processing(
            "Approve " +
              this.valueToken +
              " " +
              token.name +
              " to be spent by SWAP contract."
          );

          await this.token.methods
            .approve(options.thousandXSWAPAddress, tokenAmount)
            .send({ from: this.account });
        }

        const baseAllowance = await this.baseToken.methods
          .allowance(this.account, options.thousandXSWAPAddress)
          .call();

        if (0 > Math.compare(baseAllowance, baseAmount)) {
          this.processing(
            "Approve " +
              this.valueBaseToken +
              " 1000x to be spent by SWAP contract."
          );

          await this.baseToken.methods
            .approve(options.thousandXSWAPAddress, baseAmount)
            .send({ from: this.account });
        }

        this.processing("Adding Liquidity in progress.");

        const receipt = await this.swapContract.methods
          .addLiquidity(this.tokenSelect, tokenAmount, baseAmount)
          .send({ from: this.account });

        this.success(
          "Liquidity has been added. TX: " + receipt.transactionHash
        );
      } catch (e) {
        this.errorRPC(e);
      }
    },
    errorRPC(e) {
      if (e.message.startsWith("Internal JSON-RPC error.")) {
        const error = JSON.parse(e.message.substring(24));
        this.error(error.message);
      } else if (e.message.startsWith("MetaMask Tx Signature:")) {
        this.error(e.message.substring(23));
      } else {
        this.error("Something went wrong.");
        console.log(e.message);
      }
    },
    error(massage) {
      this.$emit("status", "Error", massage);
    },
    info(massage) {
      this.$emit("status", "Info", massage);
    },
    success(massage) {
      this.$emit("status", "Success", massage);
    },
    warning(massage) {
      this.$emit("status", "Warning", massage);
    },
    processing(massage) {
      this.$emit("status", "Processing", massage);
    },
  },
  data() {
    return {
      baseTokenAddress: "0x",
      tokenSelect: "",
      valueToken: 0,
      valueBaseToken: 0,
      swapContract: null,
      token: null,
      baseToken: null,
    };
  },
  mounted: async function () {
    if (this.web3) {
      this.swapContract = new this.web3.eth.Contract(
        ThousandXSWAPABI,
        options.thousandXSWAPAddress
      );

      this.baseTokenAddress = await this.swapContract.methods
        .getBaseToken()
        .call();

      this.baseToken = new this.web3.eth.Contract(
        IERC20ABI,
        this.baseTokenAddress
      );

      const index = this.tokens.findIndex(
        (token) => token.address == this.baseTokenAddress
      );
      this.tokenSelect = this.tokens[(index + 1) % this.tokens.length].address;
    }
  },
  watch: {
    tokenSelect: function (tokenAddress) {
      this.token = new this.web3.eth.Contract(IERC20ABI, tokenAddress);
    },
  },
  computed: {
    web3: {
      get() {
        return this.$store.state.web3;
      },
      set(value) {
        this.$store.state.web3 = value;
      },
    },
    account: {
      get() {
        return this.$store.state.account;
      },
      set(value) {
        this.$store.state.account = value;
      },
    },
    tokens: {
      get() {
        return this.$store.state.tokens;
      },
      set(value) {
        this.$store.state.tokens = value;
      },
    },
  },
};
</script>

<style style lang="scss" scoped>
.add-liquidity-controls {
  position: relative;
  width: 100%;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

label {
  display: block;
  min-height: 20px;
  float: left;
  background-color: #000;
  padding: 7px 15px;
  width: 40px;
}

input {
  display: block;
  min-height: 20px;
  width: calc(100% - 75px);
  float: left;
  border: none;
  padding: 7px 15px 7px 60px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-family: inherit;
  font-size: 20px;

  &.token {
    width: calc(100% - 235px);
  }
}

span {
  display: block;
  float: left;
  background-color: #999;
  color: #000;
  height: 24px;
  padding: 7px 15px 7px 60px;
  width: calc(50% - 75px);
  font-size: 20px;
}

select {
  display: block;
  min-height: 38px;
  width: 160px;
  float: left;
  padding: 6px 30px 6px 15px;
  border: none;
  background-color: #222;
  color: #fefefe;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
  font-size: 20px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;

  &[disabled] {
    background-color: #333;
    color: #bbb;
  }
}

.max {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 10px;
  text-align: center;
  padding: 2px 5px;
  border: 1px solid #000;
  border-radius: 2px;
}
</style>
