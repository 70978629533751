import BigNumber from "bignumber.js";

function toInt(value, decimals) {
  const bigValue = new BigNumber(10);
  return bigValue.pow(decimals).times(value).toString(10);
}

function toDecimal(value, decimals) {
  const bigValue = new BigNumber(10);
  return bigValue.pow(-decimals).times(value).toNumber();
}

function compare(val1, val2) {
  const bigVal1 = new BigNumber(val1);
  const bigVal2 = new BigNumber(val2);

  return bigVal1.comparedTo(bigVal2);
}

function add(val1, val2) {
  const bigVal1 = new BigNumber(val1);
  const bigVal2 = new BigNumber(val2);

  return bigVal1.plus(bigVal2).toString(10);
}

function sub(val1, val2) {
  const bigVal1 = new BigNumber(val1);
  const bigVal2 = new BigNumber(val2);

  return bigVal1.minus(bigVal2).toString(10);
}

export { toInt, toDecimal, compare, add, sub };
