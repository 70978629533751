<template>
  <div class="login">
    <span class="info">{{ info }}</span>
    <form>
      <label for="name">Username:</label>
      <input type="text" name="name" v-model="name" />
      <label for="password">Password:</label>
      <input type="password" name="password" v-model="password" />
      <input type="submit" name="login" value="Login" @click.prevent="login" />
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { options } from "@/settings/options.js";

export default {
  name: "Login",
  data() {
    return {
      info: "",
      name: "",
      password: "",
    };
  },
  methods: {
    async login() {
      try {
        const res = await axios.post(options.apiURL + "/auth/", {
          name: this.name,
          password: this.password,
        });

        if (200 == res.status) {
          this.jwt = res.data.access_token;
          this.$router.push("tokens");
        }
      } catch (e) {
        this.info = e.response.data.message;
      }
    },
  },
  mounted: async function () {
    if (this.jwt) this.$router.push("tokens");
  },
  computed: {
    jwt: {
      get() {
        return this.$store.state.jwt;
      },
      set(value) {
        this.$store.state.jwt = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  padding: 7px 15px;
}

label {
  display: block;
  width: calc(100% - 30px);
  min-height: 20px;
  padding: 7px 15px;

  font-size: 25px;
}

input {
  display: block;
  width: calc(100% - 30px);
  min-height: 20px;
  border: none;
  padding: 7px 15px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-family: inherit;
  font-size: 20px;
}

input[type="submit"] {
  border: none;
  padding: 7px 15px;
  color: #fff;
  background-color: #8a8cff;
  width: 100%;

  &:hover {
    opacity: 0.7;
    background-color: #4f52ff;
    color: #1e1d1c;
  }
}
</style>
