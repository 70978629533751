<template>
  <div id="nav">
    <router-link to="/">Home</router-link>
    <router-link to="/swap">Swap</router-link>
    <router-link to="/login" v-show="!jwt">Login</router-link>
    <router-link to="/liquidity" v-show="jwt">Liquidity</router-link>
    <router-link to="/tokens" v-show="jwt">Tokens</router-link>
  </div>
  <router-view />
</template>

<script>
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import axios from "axios";
import { options } from "@/settings/options.js";

export default {
  name: "App",
  computed: {
    provider: {
      get() {
        return this.$store.state.provider;
      },
      set(value) {
        this.$store.state.provider = value;
      },
    },
    web3: {
      get() {
        return this.$store.state.web3;
      },
      set(value) {
        this.$store.state.web3 = value;
      },
    },
    chainId: {
      get() {
        return this.$store.state.chainId;
      },
      set(value) {
        this.$store.state.chainId = value;
      },
    },
    account: {
      get() {
        return this.$store.state.account;
      },
      set(value) {
        this.$store.state.account = value;
      },
    },
    jwt: {
      get() {
        return this.$store.state.jwt;
      },
      set(value) {
        this.$store.state.jwt = value;
      },
    },
    tokens: {
      get() {
        return this.$store.state.tokens;
      },
      set(value) {
        this.$store.state.tokens = value;
      },
    },
  },
  mounted: async function () {
    this.provider = await detectEthereumProvider({ mustBeMetaMask: true });

    if (this.provider) {
      this.web3 = new Web3(this.provider);

      this.chainId = await this.provider.request({
        method: "eth_chainId",
      });

      this.provider.on("chainChanged", (chainId) => {
        this.chainId = chainId;
      });

      const accounts = await this.provider.request({
        method: "eth_accounts",
      });
      this.account = accounts[0];

      this.provider.on("accountsChanged", (accounts) => {
        this.account = accounts[0];
      });
    }

    const res = await axios.get(
      options.apiURL + "/swap/" + options.swapID + "/token/"
    );
    if (200 == res.status) {
      this.tokens = res.data;
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

body {
  font-size: 20px;
}

#app {
  max-width: 75rem;
  margin: 60px auto 60px auto;

  font-family: "VT323", monospace;
  color: #fefefe;
  background-color: #1e1d1c;
  border-radius: 5px;
  border: 3px solid rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: 10px 10px 5px rgba(10, 10, 10, 0.1);
  box-shadow: 10px 10px 5px rgba(10, 10, 10, 0.1);

  a {
    color: #8a8cff;

    &:hover,
    &:focus {
      color: #5356ff;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-size: 25px;
    font-weight: bold;
    display: inline-block;
    padding: 7px 20px;
    margin-right: 10px;
    color: #000;
    background: #888;
    text-decoration: none;

    &.router-link-exact-active {
      color: #333 !important;
      background: #ddd !important;
    }

    &:hover,
    &:focus {
      color: #444;
      background: #999;
    }
  }
}

$bg-url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAZ0lEQVRo3u3aIQ6AMBBE0aG2qeKKFRyKKyJrGxQJhgOUvK/WvozdrZQcc+asNXsWbYxcz92zdj2trbvEu5KfBAICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgLy0fL/WjfTvggfPjHm/AAAAABJRU5ErkJggg==";
$bg-width: 50px;
$bg-height: 50px;

/* Animations */
@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: $bg-width $bg-height;
  }
}

@-webkit-keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: $bg-width $bg-height;
  }
}

/* Main styles */
body {
  background-image: url($bg-url);
  background-color: #1e1d1c;

  -webkit-animation: bg-scrolling-reverse 0.92s infinite; /* Safari 4+ */
  -moz-animation: bg-scrolling-reverse 0.92s infinite; /* Fx 5+ */
  -o-animation: bg-scrolling-reverse 0.92s infinite; /* Opera 12+ */
  animation: bg-scrolling-reverse 0.92s infinite; /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

@media screen and (max-width: 960px) {
  #app {
    margin: 5px auto 0 auto;
  }
}
</style>
