<template>
  <div class="liquidity">
    <div class="top">
      <img alt="1000x logo" class="logo" src="../assets/img/swap_logo.png" />
      <div class="intro">
        <h1>Add/Remove Liquidity</h1>
        <p>
          The owner of the swap contract can add or remove liquidity on this
          page.
        </p>
        <p>
          The token swap page uses the ThousandXSWAP contract to do the
          swapping, in order to get more information on it please visit the
          <a href="https://gitlab.com/musdasch/thousandxswap" target="_blank"
            >GitLab</a
          >
          page.
        </p>
      </div>
    </div>
    <IsMetaMask>
      <h2>Status</h2>
      <StatusBox :status="infoStatus" :text="infoText" />
      <h2>Liquidity</h2>
      <InfoLiquidity @status="setStatusBox" />
      <h2>Add Liquidity</h2>
      <AddLiquidityControls @status="setStatusBox" :disabled="disabled" />
      <h2>Remove Liquidity</h2>
      <RemoveLiquidityControls @status="setStatusBox" :disabled="disabled" />
    </IsMetaMask>
  </div>
</template>

<script>
import StatusBox from "@/components/StatusBox.vue";
import InfoLiquidity from "@/components/InfoLiquidity.vue";
import AddLiquidityControls from "@/components/AddLiquidityControls.vue";
import RemoveLiquidityControls from "@/components/RemoveLiquidityControls.vue";
import IsMetaMask from "@/components/IsMetaMask.vue";

export default {
  name: "AddLiquidity",
  data() {
    return {
      infoText: "Ready.",
      infoStatus: "Info",
      disabled: false,
    };
  },
  components: {
    StatusBox,
    InfoLiquidity,
    AddLiquidityControls,
    RemoveLiquidityControls,
    IsMetaMask,
  },
  methods: {
    setStatusBox(status, text) {
      this.infoStatus = status;
      this.infoText = text;
      this.disabled = status === "Processing";
    },
  },
  mounted: async function () {
    if (!this.jwt) this.$router.push("login");
  },
  computed: {
    jwt: {
      get() {
        return this.$store.state.jwt;
      },
      set(value) {
        this.$store.state.jwt = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.liquidity {
  padding: 0 30px 30px 30px;

  .top::after {
    content: "";
    display: table;
    clear: both;
  }

  .logo {
    margin-top: 30px;
    max-width: 160px;
  }

  .intro {
    width: calc(100% - 220px);
    padding: 0 30px;
    float: right;
  }
}

@media screen and (max-width: 660px) {
  .liquidity {
    .logo {
      margin: 10px auto;
      max-width: 100%;
    }

    .intro {
      width: calc(100% - 20px);
      padding: 0 10px;
      float: none;
    }
  }
}
</style>
