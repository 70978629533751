<template>
  <div
    :style="{ 'background-color': bgColor, 'border-color': borderColor }"
    :class="{ 'status-box': true, processing: isProcessing }"
  >
    <span class="status" :style="{ color: statusColor }">{{ status }}:</span>
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "StatusBox",
  props: {
    text: String,
    status: String,
  },
  data() {
    return {
      bgColor: "#111",
      borderColor: "#333",
      statusColor: "#fefefe",
      isProcessing: false,
    };
  },
  watch: {
    status: function (val, oldVal) {
      this.isProcessing = false;
      if (val !== oldVal) {
        if ("Info" == val) {
          this.bgColor = "#111";
          this.borderColor = "#333";
          this.statusColor = "#fefefe";
        } else if ("Success" == val) {
          this.bgColor = "#141";
          this.borderColor = "#393";
          this.statusColor = "#2F2";
        } else if ("Warning" == val) {
          this.bgColor = "#331";
          this.borderColor = "#993";
          this.statusColor = "#EE2";
        } else if ("Error" == val) {
          this.bgColor = "#311";
          this.borderColor = "#933";
          this.statusColor = "#E22";
        } else if ("Processing" == val) {
          this.bgColor = "#111";
          this.borderColor = "#999";
          this.statusColor = "#22f";
          this.isProcessing = true;
        } else {
          this.bgColor = "#111";
          this.borderColor = "#333";
          this.statusColor = "#fefefe";
        }
      }
    },
  },
};
</script>

<style style lang="scss" scoped>
@keyframes animatedBackground {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 64px 0px;
  }
}

.status-box {
  border: 2px solid #333;
  background-color: #111;
  padding: 10px 25px;
  margin: 0 0 20px 0;
}

.processing {
  background-image: url(../assets/img/processing.png);
  background-size: 64px 64px;
  background-position: 0px 0px;
  animation: animatedBackground 1s linear infinite;
}
</style>
