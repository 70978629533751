<template>
  <div
    id="connect-metamask"
    v-show="provider !== null && account === undefined"
  >
    <img
      alt="No MetaMask logo"
      class="logo"
      src="../assets/img/connect_metamask.png"
    />
    <div class="text">
      <h2>Connect MetaMask</h2>
      <p>
        Almost done, to swap tokens you have to connect MetaMask with this
        website, to do that click the button below.
      </p>
      <Button
        @btn-click="requestAccounts()"
        text="Connect MetaMask"
        fx="expanded"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "ConnectMetaMask",
  components: {
    Button,
  },
  computed: {
    provider: {
      get() {
        return this.$store.state.provider;
      },
      set(value) {
        this.$store.state.provider = value;
      },
    },
    account: {
      get() {
        return this.$store.state.account;
      },
      set(value) {
        this.$store.state.account = value;
      },
    },
  },
  methods: {
    async requestAccounts() {
      await this.provider.request({
        method: "eth_requestAccounts",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#connect-metamask {
  padding: 30px;
  border: 2px solid #333;
  background: #111;
  margin: 40px 0;

  .logo {
    margin-top: 30px;
    max-width: 160px;
  }

  .text {
    width: calc(100% - 220px);
    padding: 0 30px;
    float: right;
  }
}
</style>
