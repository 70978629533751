<template>
  <button
    @click="onClick()"
    :class="fx"
    :style="{ background: color }"
    :disabled="disabled"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String,
    color: String,
    fx: String,
    disabled: Boolean,
  },
  methods: {
    onClick() {
      this.$emit("btn-click");
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  border: none;
  padding: 7px 15px;
  color: #fff;
  background-color: #8a8cff;

  font-family: "VT323", monospace;
  font-size: 20px;

  &:hover {
    opacity: 0.7;
    background-color: #4f52ff;
    color: #1e1d1c;
    cursor: pointer;
  }

  &.expanded {
    width: 100%;
  }

  &:disabled,
  &button[disabled] {
    background-color: #8a8cff;
    color: #fff;
    opacity: 0.5;
    &:hover {
      background-color: #8a8cff;
      color: #fff;
      opacity: 0.5;
    }
  }
}
</style>
