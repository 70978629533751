import { createStore } from "vuex";

export default createStore({
  state: {
    provider: null,
    web3: null,
    chainId: "0x",
    account: undefined,
    jwt: "",
    tokens: [],
  },
  mutations: {},
  actions: {},
  modules: {},
});
