<template>
  <div class="swap">
    <div class="top">
      <img alt="1000x logo" class="logo" src="../assets/img/swap_logo.png" />
      <div class="intro">
        <h1>Swap</h1>
        <p>
          On this page, you can swap your 1000x tokens in to other tokens, or
          you can do it in reverse.
        </p>
        <p>
          The token swap page uses the ThousandXSWAP contract to do the
          swapping, in order to get more information on it please visit the
          <a href="https://gitlab.com/musdasch/thousandxswap" target="_blank"
            >GitLab</a
          >
          page.
        </p>
        <div class="important">
          <strong>Important:</strong><br />
          <p>
            This Page runs entirely in your browser and interacts with MetaMask
            directly with the blockchain. The owner of this page can't refund or
            change anything which as been written to the blockchain.
          </p>
        </div>
        <div class="warning">
          <strong>Warning:</strong><br />
          <p>
            This Page is an early prototype and may have some issues. It's only
            online to get tested. You have been warned, so please do use this
            page cautiously.
          </p>
        </div>
      </div>
    </div>
    <IsMetaMask>
      <h2>Swap Tokens</h2>
      <StatusBox :status="infoStatus" :text="infoText" />
      <SwapControls
        @status="setStatusBox"
        :disabled="disabled"
        :initFromAddress="initFromAddress"
        :initToAddress="initToAddress"
      />
    </IsMetaMask>
  </div>
</template>

<script>
import StatusBox from "@/components/StatusBox.vue";
import IsMetaMask from "@/components/IsMetaMask.vue";
import SwapControls from "@/components/SwapControls.vue";

export default {
  name: "Swap",
  props: {
    initFromAddress: String,
    initToAddress: String,
  },
  data() {
    return {
      infoText: "Ready.",
      infoStatus: "Info",
      disabled: false,
    };
  },
  components: {
    StatusBox,
    IsMetaMask,
    SwapControls,
  },
  methods: {
    setStatusBox(status, text) {
      this.infoStatus = status;
      this.infoText = text;
      this.disabled = status === "Processing";
    },
  },
};
</script>

<style lang="scss" scoped>
.swap {
  padding: 0 30px 30px 30px;

  ul {
    padding-left: 15px;
  }

  .top::after {
    content: "";
    display: table;
    clear: both;
  }

  .important {
    border: 2px solid #ff0;
    background-color: #331;
    padding: 10px 15px;
    margin: 15px 0;
  }

  .warning {
    border: 2px solid #f00;
    background-color: #311;
    padding: 10px 15px;
    margin: 15px 0;
  }

  .logo {
    margin-top: 30px;
    max-width: 160px;
  }

  .no-metamask,
  .connect-metamask {
    padding: 30px;
    border: 2px solid #333;
    background: #111;
    margin: 40px 0;
  }

  .intro,
  .no-metamask .text,
  .connect-metamask .text {
    width: calc(100% - 220px);
    padding: 0 30px;
    float: right;
  }

  .profiles {
    &::after {
      content: "";
      display: table;
      clear: both;
    }

    .mintme {
      border: 2px solid #333;
      margin-bottom: 20px;
      padding: 10px 20px;
      background-color: #111;
    }

    .profile {
      position: relative;
      width: calc(50% - 102px);
      float: left;
      border: 2px solid #333;
      margin: 0 58px 0 0;
      padding: 10px 20px;
      background-color: #111;

      &:last-child {
        margin: 0 0 0 58px;

        &::before {
          content: "➠";
          position: absolute;
          top: 50%;
          left: -70px;
          font-size: 50px;
          transform: translateY(-50%);
        }
      }
    }
  }

  .rate {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 15px;
  }
}

@media screen and (max-width: 660px) {
  .swap {
    .logo {
      margin: 10px auto;
      max-width: 100%;
    }

    .intro {
      width: calc(100% - 20px);
      padding: 0 10px;
      float: none;
    }
  }
}
</style>
