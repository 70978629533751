<template>
  <div class="no-metamask" v-show="provider === null">
    <img
      alt="No MetaMask logo"
      class="logo"
      src="../assets/img/no_metamask.png"
    />
    <div class="text">
      <h2>No MetaMask installed</h2>
      <p>
        There is no MetaMask Installed to use this application, please install
        MetaMask.
      </p>
      <p>
        You will find information on how to install it
        <a href="https://metamask.io/download" target="_blank">here</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoMetaMask",
  computed: {
    provider: {
      get() {
        return this.$store.state.provider;
      },
      set(value) {
        this.$store.state.provider = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.no-metamask {
  padding: 30px;
  border: 2px solid #333;
  background: #111;
  margin: 40px 0;

  .logo {
    margin-top: 30px;
    max-width: 160px;
  }

  .text {
    width: calc(100% - 220px);
    padding: 0 30px;
    float: right;
  }
}
</style>
