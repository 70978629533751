import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/swap",
    name: "Swap",
    component: () => import(/* webpackChunkName: "swap" */ "../views/Swap.vue"),
  },
  {
    path: "/swap/:inputCurrency/:outputCurrency",
    name: "SwapSelect",
    component: () =>
      import(/* webpackChunkName: "swapselect" */ "../views/SwapSelect.vue"),
  },
  {
    path: "/liquidity",
    name: "Liquidity",
    component: () =>
      import(/* webpackChunkName: "liquidity" */ "../views/Liquidity.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/tokens",
    name: "Tokens",
    component: () =>
      import(/* webpackChunkName: "tokens" */ "../views/Tokens.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
