<template>
  <div class="home">
    <img alt="1000x logo" class="logo" src="../assets/img/logo.png" />
    <div class="intro">
      <h1>Welcome to 1000x Token Page</h1>
      <p>
        The 1000x token has been the first token that has been deployed on the
        MintMe blockchain with mintme.com platform and has been created on 23
        May 2020.
      </p>
      <p>There are the following services where you can use the 1000x token.</p>
      <ul>
        <li>
          The <router-link to="/swap">1000x swap</router-link>, where you can
          exchange your 1000x token in to 23 other tokens.
        </li>
        <li>
          The
          <a href="https://www.dogswap.xyz/swap" target="_blank">DogSwap</a>,
          where you can exchange the 1000x token in to BONE, PMINT, DOGSWAP or
          into other tokens.
        </li>
        <li>
          The
          <a
            href="https://www.mintme.com/token/1000x/MINTME/trade"
            target="_blank"
            >MintMe.com</a
          >, where you can exchange the 1000x token in to BNB, BTC, ETH and
          USDC.
        </li>
      </ul>
      <p>
        The goal of this token is to have something valuable that can be given
        to other people or exchanged for other goods.
      </p>
    </div>
    <div class="grid">
      <div class="item">
        <div>
          <h2>Exchanges</h2>
          <p>The 1000x token is listet on mintme.com and highpay-pool.com</p>
          <ul>
            <li>
              <a target="_blank" href="https://www.mintme.com/token/1000x"
                >mintme.com</a
              >
            </li>
            <li>
              <a target="_blank" href="https://www.dogswap.xyz/swap">DogSwap</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="item">
        <div>
          <h2>MintMe Geth node</h2>
          <p>
            I also run a MintMe Geth node, you can use the node.1000x.ch host in
            order to connect to the MintMe network via RPC.
          </p>
          <ul>
            <li>
              <a target="_blank" href="https://github.com/etclabscore/core-geth"
                >Source code</a
              >
            </li>
            <li>
              <a target="_blank" href="https://chainlist.org/chain/24734"
                >ChainList</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="item">
        <div>
          <h2>ERC-20</h2>
          <p>
            About the ERC-20 standard this token is based on.<br /><br /><a
              target="_blank"
              href="https://www.mintme.com/coin/"
              >about</a
            >
          </p>
        </div>
      </div>
      <div class="item">
        <div>
          <h2>Used Blockchain</h2>
          <p>
            The 1000x Token is deployed on the MintMe Blockchain. Find all the
            information about this project.<br /><a
              target="_blank"
              href="https://www.mintme.com/coin/"
              >about</a
            >
          </p>
        </div>
      </div>
      <div class="item">
        <div>
          <h2>Explorer</h2>
          <p>
            Check out all transactions which have been done.<br /><a
              target="_blank"
              href="https://www.mintme.com/explorer/addr/0x7b535379bbafd9cd12b35d91addabf617df902b2#tab_addr_2"
              >explorer</a
            >
          </p>
        </div>
      </div>
      <div class="item">
        <div>
          <h2>Wallets</h2>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://github.com/mintme-com/wallet/releases"
                >mintMe Wallet</a
              >
            </li>
            <li>
              <a target="_blank" href="https://www.mintme.com/web-wallet/"
                >mintMe Web Wallet</a
              >
            </li>
            <li><a target="_blank" href="https://metamask.io/">MetaMask</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss" scoped>
.home {
  padding: 0 30px 30px 30px;

  ul {
    padding-left: 15px;
  }

  .logo {
    margin-top: 30px;
    max-width: 160px;
  }

  .intro {
    width: calc(100% - 220px);
    padding: 0 30px;
    float: right;
  }

  .grid {
    display: grid;
    width: 100%;
    margin-top: 30px;
    grid-gap: 0;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 250px 250px;
  }

  .item {
    display: flex;
    padding: 7px 15px;
  }
}

@media screen and (max-width: 960px) {
  .home .grid {
    grid-template-columns: 50% 50%;
    grid-template-rows: 200px 200px 200px 200px;
  }
}

@media screen and (max-width: 660px) {
  .home {
    .logo {
      margin: 10px auto;
      max-width: 100%;
    }

    .intro {
      width: calc(100% - 20px);
      padding: 0 10px;
      float: none;
    }
  }
}

@media screen and (max-width: 560px) {
  .home {
    .grid {
      grid-template-columns: 100%;
      grid-template-rows: 200px 200px 200px 200px 200px 200px 200px;
    }
  }
}
</style>
