<template>
  <div class="swap-controls">
    <span class="rate">
      Rate: {{ fromDisplayValue / toDisplayValue }} {{ fromToken.name }}/{{
        toToken.name
      }}
    </span>
    <div class="from">
      <label>From:</label>
      <button class="max" @click="setToMax" :disabled="disabled">max.</button>
      <input
        type="number"
        v-model="fromDisplayValue"
        :disabled="disabled"
        step="any"
      />
      <select v-model="fromAddress" :disabled="disabled">
        <template v-for="token in tokens" :key="token.address">
          <option v-if="!token.hidden" :value="token.address">
            {{ token.name }}
          </option>
        </template>
      </select>
    </div>

    <div class="to">
      <label>To:</label>
      <span class="resive">{{ toDisplayValue }}</span>
      <select v-model="toAddress" :disabled="disabled">
        <template v-for="token in tokens" :key="token.address">
          <option v-if="!token.hidden" :value="token.address">
            {{ token.name }}
          </option>
        </template>
      </select>
    </div>

    <Button @btn-click="swap" text="Swap" fx="expanded" :disabled="disabled" />
    Add token to MetaMask:
    <AddTokenButton
      @status="(status, message) => $emit('status', status, message)"
      :disabled="disabled"
      :token="fromToken"
    />
    <AddTokenButton
      @status="(status, message) => $emit('status', status, message)"
      :disabled="disabled"
      :token="toToken"
    />
  </div>
</template>

<script>
import { options } from "@/settings/options.js";
import BigNumber from "bignumber.js";
import * as Math from "@/utils/Math.js";
import Button from "@/components/Button.vue";
import AddTokenButton from "@/components/AddTokenButton.vue";

const ThousandXSWAPABI = require("@/abi/ThousandXSWAP.json");
const IERC20ABI = require("@/abi/IERC20.json");

export default {
  name: "SwapControls",
  props: {
    disabled: Boolean,
    initFromAddress: String,
    initToAddress: String,
  },
  data() {
    return {
      fromValue: new BigNumber(),
      fromDisplayValue: 0,
      fromAddress: "",
      fromToken: {},
      fromContract: null,
      toValue: new BigNumber(),
      toDisplayValue: 0,
      toAddress: "",
      toToken: {},
      infoText: "Ready.",
      infoStatus: "Info",
      swapContract: null,
    };
  },
  components: {
    Button,
    AddTokenButton,
  },
  methods: {
    async swap() {
      const fromAmount = this.fromValue;
      const toAmount = this.toValue;

      if (0 <= Math.compare(0, fromAmount)) {
        this.error("The swap amount can't be zero or less.");
        return;
      }

      try {
        const balance = this.web3.utils.fromWei(
          await this.web3.eth.getBalance(this.account),
          "ether"
        );
        const fromBalance = await this.fromContract.methods
          .balanceOf(this.account)
          .call();
        const tokenAllowance = await this.fromContract.methods
          .allowance(this.account, options.thousandXSWAPAddress)
          .call();

        if (0.0004 >= balance) {
          this.error(
            "Your MintMe balance is too low, in order to pay the gas prices you have to have at least 0.0004 MintMe in your wallet."
          );
          return;
        }

        if (0 > Math.compare(fromBalance, fromAmount)) {
          this.error(
            "Not enough " +
              this.fromToken.name +
              " Token. Your balance is " +
              Math.toDecimal(fromBalance, this.fromToken.decimals) +
              "."
          );
          return;
        }

        if (0 > Math.compare(tokenAllowance, fromAmount)) {
          this.processing(
            "Approve " +
              this.fromDisplayValue +
              " " +
              this.fromToken.name +
              " to be spent by SWAP contract."
          );

          try {
            await this.fromContract.methods
              .approve(options.thousandXSWAPAddress, fromAmount)
              .send({ from: this.account });
          } catch (e) {
            this.errorRPC(e);
            return;
          }
        }

        this.processing(
          "Swapping " +
            this.fromDisplayValue +
            " " +
            this.fromToken.name +
            " to " +
            this.toDisplayValue +
            " " +
            this.toToken.name +
            "."
        );

        const receipt = await this.swapContract.methods
          .swap(this.fromAddress, this.toAddress, fromAmount, toAmount)
          .send({ from: this.account });

        this.success(
          "Token have been successfully swapped. TX: " + receipt.transactionHash
        );
      } catch (e) {
        this.errorRPC(e);
        return;
      }
    },
    async setToMax() {
      const max = await this.fromContract.methods
        .balanceOf(this.account)
        .call();

      this.fromDisplayValue = Math.toDecimal(max, this.fromToken.decimals);
    },
    async update() {
      if (0 < this.fromValue) {
        const fromValue = this.fromValue;

        try {
          const value = await this.swapContract.methods
            .swapPreview(this.fromAddress, this.toAddress, fromValue)
            .call();

          this.toValue = value;
        } catch (e) {
          this.errorRPC(e);

          this.toValue = 0;
          return;
        }
      } else {
        this.toValue = 0;
      }
    },
    errorRPC(e) {
      if (e.message.startsWith("Internal JSON-RPC error.")) {
        const error = JSON.parse(e.message.substring(24));
        this.error(error.message);
      } else if (e.message.startsWith("MetaMask Tx Signature:")) {
        this.error(e.message.substring(23));
      } else {
        this.error("Something went wrong.");
        console.log(e.message);
      }
    },
    error(message) {
      this.$emit("status", "Error", message);
    },
    info(message) {
      this.$emit("status", "Info", message);
    },
    success(message) {
      this.$emit("status", "Success", message);
    },
    warning(message) {
      this.$emit("status", "Warning", message);
    },
    processing(message) {
      this.$emit("status", "Processing", message);
    },
  },
  mounted: function () {
    this.info("Ready.");
    setTimeout(async () => {
      if (this.web3) {
        this.swapContract = new this.web3.eth.Contract(
          ThousandXSWAPABI,
          options.thousandXSWAPAddress
        );

        this.swapContract.events.updateLiquidity((error) => {
          if (!error) {
            this.update();
          }
        });

        this.swapContract.events.newSWAP((error) => {
          if (!error) {
            this.update();
          }
        });

        if (
          this.initFromAddress &&
          this.tokens.some(
            (e) =>
              e.address.toLowerCase() === this.initFromAddress.toLowerCase()
          )
        ) {
          this.fromAddress = this.tokens.filter((token) => {
            return (
              token.address.toLowerCase() === this.initFromAddress.toLowerCase()
            );
          })[0].address;
        } else {
          this.fromAddress = await this.swapContract.methods
            .getBaseToken()
            .call();
        }

        this.fromToken = this.tokens.find(
          (token) => token.address == this.fromAddress
        );

        const fromIndex = this.tokens.findIndex(
          (token) => token.address == this.fromAddress
        );

        if (
          this.initToAddress &&
          this.tokens.some(
            (e) => e.address.toLowerCase() === this.initToAddress.toLowerCase()
          )
        ) {
          this.toAddress = this.tokens.filter((token) => {
            return (
              token.address.toLowerCase() === this.initToAddress.toLowerCase()
            );
          })[0].address;
        } else {
          this.toAddress = this.tokens.filter((token) => {
            return !token.hidden;
          })[(fromIndex + 1) % this.tokens.length].address;
        }

        this.fromContract = new this.web3.eth.Contract(
          IERC20ABI,
          this.fromAddress
        );
      }
    }, 750);
  },
  watch: {
    fromDisplayValue: async function (newFromDisplayValue) {
      this.fromValue = Math.toInt(newFromDisplayValue, this.fromToken.decimals);
      this.update();
    },
    toValue: function (newToValue) {
      this.toDisplayValue = Math.toDecimal(newToValue, this.toToken.decimals);
    },
    fromAddress: async function (address, oldAddress) {
      if (address == this.toAddress) {
        this.toAddress = oldAddress;
      }

      this.fromToken = this.tokens.find(
        (token) => token.address == this.fromAddress
      );

      this.fromValue = Math.toInt(
        this.fromDisplayValue,
        this.fromToken.decimals
      );

      this.fromContract = new this.web3.eth.Contract(IERC20ABI, address);

      this.update();
    },
    toAddress: async function (address, oldAddress) {
      if (address == this.fromAddress) {
        this.fromAddress = oldAddress;
      }

      this.toToken = this.tokens.find(
        (token) => token.address == this.toAddress
      );

      this.update();
    },
  },
  computed: {
    web3: {
      get() {
        return this.$store.state.web3;
      },
      set(value) {
        this.$store.state.web3 = value;
      },
    },
    account: {
      get() {
        return this.$store.state.account;
      },
      set(value) {
        this.$store.state.account = value;
      },
    },
    tokens: {
      get() {
        return this.$store.state.tokens;
      },
      set(value) {
        this.$store.state.tokens = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.swap-controls {
  .from,
  .to {
    position: relative;

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .rate {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 15px;
  }

  label {
    display: block;
    min-height: 20px;
    float: left;
    background-color: #000;
    padding: 7px 15px;
    width: 40px;
  }

  .max {
    position: absolute;
    top: 10px;
    left: 80px;
    font-size: 10px;
    text-align: center;
    padding: 2px 5px;
    border: 1px solid #000;
    border-radius: 2px;
  }

  input {
    display: block;
    min-height: 20px;
    width: calc(100% - 305px);
    float: left;
    border: none;
    padding: 7px 15px 7px 60px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    font-family: inherit;
    font-size: 20px;
  }

  .resive {
    display: block;
    float: left;
    background-color: #999;
    color: #000;
    height: 20px;
    padding: 7px 15px 7px 60px;
    width: calc(100% - 305px);
    font-size: 20px;
  }

  select {
    display: block;
    min-height: 34px;
    width: 160px;
    float: left;
    padding: 6px 30px 6px 15px;
    border: none;
    background-color: #222;
    color: #fefefe;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    font-family: inherit;
    font-size: 20px;

    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    background-origin: content-box;
    background-position: right -1rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;

    &[disabled] {
      background-color: #333;
      color: #bbb;
    }
  }
}
</style>
