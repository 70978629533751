<template>
  <div class="remove-liquidity-controls">
    <Button
      @btn-click="removeLiquidity"
      text="remove Liquidity"
      :disabled="disabled"
    />
    <select v-model="tokenSelect" :disabled="disabled">
      <option
        :key="token.address"
        v-for="token in tokens"
        :value="token.address"
        v-show="token.address != baseTokenAddress"
      >
        {{ token.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { options } from "@/settings/options.js";
import Button from "@/components/Button.vue";

const ThousandXSWAPABI = require("@/abi/ThousandXSWAP.json");

export default {
  name: "RemoveLiquidityControls",
  components: {
    Button,
  },
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      baseTokenAddress: "0x",
      tokenSelect: "0x",
      swapContract: null,
    };
  },
  methods: {
    async removeLiquidity() {
      const token = this.tokens.find(
        (token) => token.address == this.tokenSelect
      );

      this.processing("Removing liquidity from " + token.name + ".");

      try {
        const receipt = await this.swapContract.methods
          .removeLiquidity(token.address)
          .send({ from: this.account });

        this.success(
          "Liquidity has been removed. TX: " + receipt.transactionHash
        );
      } catch (e) {
        this.errorRPC(e);
      }
    },
    errorRPC(e) {
      if (e.message.startsWith("Internal JSON-RPC error.")) {
        const error = JSON.parse(e.message.substring(24));
        this.error(error.message);
      } else if (e.message.startsWith("MetaMask Tx Signature:")) {
        this.error(e.message.substring(23));
      } else {
        this.error("Something went wrong.");
        console.log(e.message);
      }
    },
    error(massage) {
      this.$emit("status", "Error", massage);
    },
    info(massage) {
      this.$emit("status", "Info", massage);
    },
    success(massage) {
      this.$emit("status", "Success", massage);
    },
    warning(massage) {
      this.$emit("status", "Warning", massage);
    },
    processing(massage) {
      this.$emit("status", "Processing", massage);
    },
  },
  mounted: async function () {
    if (this.web3) {
      this.swapContract = new this.web3.eth.Contract(
        ThousandXSWAPABI,
        options.thousandXSWAPAddress
      );

      this.baseTokenAddress = await this.swapContract.methods
        .getBaseToken()
        .call();

      const index = this.tokens.findIndex(
        (token) => token.address == this.baseTokenAddress
      );
      this.tokenSelect = this.tokens[(index + 1) % this.tokens.length].address;
    }
  },
  computed: {
    web3: {
      get() {
        return this.$store.state.web3;
      },
      set(value) {
        this.$store.state.web3 = value;
      },
    },
    account: {
      get() {
        return this.$store.state.account;
      },
      set(value) {
        this.$store.state.account = value;
      },
    },
    tokens: {
      get() {
        return this.$store.state.tokens;
      },
      set(value) {
        this.$store.state.tokens = value;
      },
    },
  },
};
</script>

<style style lang="scss" scoped>
.remove-liquidity-controls {
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  button {
    float: left;
    width: calc(100% - 160px);
    height: 38px;
  }

  select {
    display: block;
    min-height: 38px;
    width: 160px;
    float: left;
    padding: 6px 30px 6px 15px;
    border: none;
    background-color: #222;
    color: #fefefe;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: inherit;
    font-size: 20px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    background-origin: content-box;
    background-position: right -1rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;

    &[disabled] {
      background-color: #333;
      color: #bbb;
    }
  }
}
</style>
