<template>
  <NoMetaMask />
  <ConnectMetaMask />
  <SwitchChainMetaMask />
  <div
    v-show="provider !== null && account !== undefined && chainId === '0x609e'"
  >
    <slot></slot>
  </div>
</template>

<script>
import NoMetaMask from "@/components/NoMetaMask.vue";
import ConnectMetaMask from "@/components/ConnectMetaMask.vue";
import SwitchChainMetaMask from "@/components/SwitchChainMetaMask.vue";

export default {
  name: "IsMetaMask",
  components: {
    NoMetaMask,
    ConnectMetaMask,
    SwitchChainMetaMask,
  },
  mounted: async function () {
    if (typeof window.ethereum !== "undefined") {
      if (window.ethereum.isMetaMask) {
        this.isMetaMask = true;
      }
    }
  },
  computed: {
    provider: {
      get() {
        return this.$store.state.provider;
      },
      set(value) {
        this.$store.state.provider = value;
      },
    },
    chainId: {
      get() {
        return this.$store.state.chainId;
      },
      set(value) {
        this.$store.state.chainId = value;
      },
    },
    account: {
      get() {
        return this.$store.state.account;
      },
      set(value) {
        this.$store.state.account = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
