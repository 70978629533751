<template>
  <div class="info-liquidity">
    <div class="info">
      1000x: {{ baseLiquidity }}; {{ tokenName }}: {{ tokenLiquidity }}
    </div>
    <select v-model="tokenSelect">
      <option
        :key="token.address"
        v-for="token in tokens"
        :value="token.address"
        v-show="token.address != baseTokenAddress"
      >
        {{ token.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { options } from "@/settings/options.js";
import * as Math from "@/utils/Math.js";

const ThousandXSWAPABI = require("@/abi/ThousandXSWAP.json");

export default {
  name: "InfoLiquidity",
  data() {
    return {
      baseTokenAddress: "0x",
      swapContract: null,
      tokenSelect: "0x",
      tokenName: "",
      tokenLiquidity: 0,
      baseLiquidity: 0,
    };
  },
  mounted: async function () {
    if (this.web3) {
      this.swapContract = new this.web3.eth.Contract(
        ThousandXSWAPABI,
        options.thousandXSWAPAddress
      );

      this.swapContract.events.updateLiquidity((error) => {
        if (!error) {
          this.update();
        }
      });

      this.swapContract.events.newSWAP((error) => {
        if (!error) {
          this.update();
        }
      });

      this.baseTokenAddress = await this.swapContract.methods
        .getBaseToken()
        .call();

      const index = this.tokens.findIndex(
        (token) => token.address == this.baseTokenAddress
      );
      this.tokenSelect = this.tokens[(index + 1) % this.tokens.length].address;
    }
  },
  methods: {
    async update() {
      const token = this.tokens.find(
        (token) => token.address == this.tokenSelect
      );

      this.tokenName = token.name;

      try {
        const liquidity = await this.swapContract.methods
          .getLiquidity(this.tokenSelect)
          .call();

        this.tokenLiquidity = Math.toDecimal(liquidity[0], token.decimals);
        this.baseLiquidity = Math.toDecimal(liquidity[1], 12);
      } catch (e) {
        this.tokenLiquidity = 0;
        this.baseLiquidity = 0;
        this.errorRPC(e);
      }
    },
    errorRPC(e) {
      if (e.message.startsWith("Internal JSON-RPC error.")) {
        const error = JSON.parse(e.message.substring(24));
        this.error(error.message);
      } else if (e.message.startsWith("MetaMask Tx Signature:")) {
        this.error(e.message.substring(23));
      } else {
        this.error("Something went wrong.");
        console.log(e.message);
      }
    },
  },
  watch: {
    tokenSelect: function () {
      this.update();
    },
  },
  computed: {
    web3: {
      get() {
        return this.$store.state.web3;
      },
      set(value) {
        this.$store.state.web3 = value;
      },
    },
    tokens: {
      get() {
        return this.$store.state.tokens;
      },
      set(value) {
        this.$store.state.tokens = value;
      },
    },
  },
};
</script>

<style style lang="scss" scoped>
.info-liquidity {
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  .info {
    width: calc(100% - 214px);
    height: 24px;
    float: left;
    border: 2px solid #333;
    background-color: #111;
    padding: 5px 25px;
  }

  select {
    display: block;
    min-height: 38px;
    width: 160px;
    float: left;
    padding: 6px 30px 6px 15px;
    border: none;
    background-color: #222;
    color: #fefefe;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: inherit;
    font-size: 20px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    background-origin: content-box;
    background-position: right -1rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
  }
}
</style>
