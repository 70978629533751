<template>
  <div class="addToken" @click="onClick()">
    <img alt="Token Icon" class="icon" :src="token.image" /> {{ token.symbol }}
  </div>
</template>

<script>
export default {
  name: "AddTokenButtonButton",
  props: {
    token: Object,
    disabled: Boolean,
  },
  methods: {
    async onClick() {
      if (this.provider && !this.disabled) {
        this.processing("Adding" + this.token.name + " to wallet.");

        try {
          await this.provider.request({
            method: "wallet_watchAsset",
            params: {
              type: this.token.standard,
              options: {
                address: this.token.address,
                symbol: this.token.symbol,
                decimals: this.token.decimals,
                image: this.token.image,
              },
            },
          });

          this.success(this.token.name + " successfully added to wallet.");
        } catch (e) {
          this.errorRPC(e);
        }
      }
    },
    errorRPC(e) {
      if (e.message.startsWith("Internal JSON-RPC error.")) {
        const error = JSON.parse(e.message.substring(24));
        this.error(error.message);
      } else if (e.message.startsWith("MetaMask Tx Signature:")) {
        this.error(e.message.substring(23));
      } else {
        this.error("Something went wrong.");
        console.log(e.message);
      }
    },
    error(massage) {
      this.$emit("status", "Error", massage);
    },
    info(massage) {
      this.$emit("status", "Info", massage);
    },
    success(massage) {
      this.$emit("status", "Success", massage);
    },
    warning(massage) {
      this.$emit("status", "Warning", massage);
    },
    processing(massage) {
      this.$emit("status", "Processing", massage);
    },
  },
  computed: {
    provider: {
      get() {
        return this.$store.state.provider;
      },
      set(value) {
        this.$store.state.provider = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.addToken {
  position: relative;
  display: inline-block;

  padding: 8px 25px 8px 50px;
  margin: 10px 5px;

  border: 2px solid #333;
  background-color: #111;
  border-radius: 20px;

  cursor: pointer;

  &:hover {
    background-color: #151515;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  }
}

img {
  position: absolute;
  top: 3px;
  left: 3px;
  width: auto;
  height: 30px;
  border-radius: 50%;
}
</style>
